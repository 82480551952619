import {createContext, useState} from 'react';
import { clienteAxios } from '../config';

const InglesContext = createContext();

const InglesProvider = ({children}) => {
    const [alerta, setAlerta] = useState(false);
    const [mensajeErrorForm, setMensajeErrorForm] = useState(null);
    const [autenticado, setAutenticado] = useState(false);
    const [cargando, setCargando] = useState(false);

    const formularioAlerta = async(msg) => {
        setMensajeErrorForm(msg);
        setAlerta(true);

        setTimeout(() => {
            setAlerta(false);
        }, 3000);
    };

    const loginUsuario = async (data) => {
        const email = data.email[0];
        const password = data.password[0];
        const datos = {
            email,
            password
        }
        const respuesta = await clienteAxios.post('/login', datos);
        console.log(respuesta);

        try {
            const respuesta = await clienteAxios.post('/login', datos);
            const user = respuesta.data.user;
            console.log(user);
            setAutenticado(true);
            localStorage.setItem('texto', user.texto);
            obtenerAudios();
        } catch (error) {
            setAlerta(true);
            setMensajeErrorForm(error.response.data.message);
            console.log(error);
            setTimeout(() => {
                setAlerta(false);
            }, 2000);
        }
    }

    const cerrarSesion = async() => {
        localStorage.removeItem('texto');
        //localStorage.removeItem('audios');
        setAutenticado(false);
        setCargando(false);            
    }

    const autenticarUsuario = async() => {
        setCargando(true);
    }

    const obtenerAudios = async() => {
        try {
            const respuesta = await clienteAxios.get('/audios');
            const dato = JSON.stringify(respuesta.data);
            localStorage.setItem('audios', dato);
        } catch (error) {
            setCargando(false);
        }
    }

    return(
        <InglesContext.Provider
            value = {{
                alerta,
                mensajeErrorForm,
                autenticado,
                cargando,
                formularioAlerta,
                loginUsuario,
                cerrarSesion,
                autenticarUsuario,
                obtenerAudios
            }}
        >{children}</InglesContext.Provider>
    )
}

export {
    InglesProvider
}

export default InglesContext;